import { buildRequestInit } from '@datocms/cda-client'
import type { TadaDocumentNode } from 'gql.tada'
import type { H3Event } from 'h3'
import __useDatoQuerySubscription from './useDatoQuerySubscription.js'
import { __objectHash } from '@lotsof/sugar/object'
import __projectConfig from '~/project.config.js'

type Options<Variables> = {
  variables?: Variables
}

export async function useDatoQuery<Result, Variables>(
  query: TadaDocumentNode<Result, Variables>,
  options?: Options<Variables> & {
    transform?: (data: any) => any
    key?: string
    cache?: boolean
    event?: H3Event<any>
    debug?: boolean
  },
) {
  const queryKey = options?.key ?? __objectHash(query)

  // options
  if (!options) {
    options = {}
  }

  // cache default
  if (options?.cache === undefined) {
    options.cache = true
  }

  if (!options?.key) {
    console.warn(
      `useDatoQuery: No key provided for query ${queryKey}. It's recomanded to provide a key through "options.key" to avoid cache issues.`,
    )
  }

  // if the query comes from the client, we make the request to the
  // server to make use of cache
  if (import.meta.client) {
    const userStore = useUserStore(),
      appStore = useAppStore(),
      draftMode = appStore.stage === 'preview',
      apiToken = userStore.apiToken

    // protect the query from being executed without an API token
    if (!apiToken) {
      throw new Error('Client: Missing API token')
    }

    const { data } = await useAsyncData(
      queryKey,
      async () => {
        const response = await fetch('/api/dato', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            query,
            options,
          }),
        })
        let responseJson = await response.json()
        responseJson = responseJson.data ?? responseJson

        // return the final data
        return responseJson
      },
      {},
    )
    let rawJson: any = data,
      finalJson: any

    // process the data
    finalJson = rawJson.value ?? rawJson
    if (options?.transform) {
      finalJson = await options.transform(finalJson)
    }

    // If we are in Draft Mode instead, and the composable is run client-side,
    // then we initiate the connection with the DatoCMS Real-time Updates API.
    if (draftMode) {
      if (options?.debug) {
        console.log('Draft mode enabled')
      }

      return __useDatoQuerySubscription<Result, Variables>({
        query,
        variables: options?.variables,
        token: apiToken,
        initialData: finalJson,
        includeDrafts: true,
        excludeInvalid: true,
        // @ts-ignore
        debug: options?.debug,
        async transform(data: any) {
          if (options?.debug) {
            console.log('Data', data)
          }
          if (options?.transform) {
            return await options.transform(data)
          }
          return data
        },
      }).data
    }

    // return the result json
    return finalJson as Result
  } else {
    const { default: UserStore }: any = await import(
        '~/server/lib/user/user.store.js'
      ),
      userStore = new UserStore(options?.event),
      apiToken = userStore.apiToken,
      draftMode = userStore.draftMode

    let cache: any | null = null,
      rawJson: any,
      finalJson: any

    // if cache is enabled, we try to get the data from the cache
    if (options?.cache && __projectConfig.cache?.enabled) {
      const __Cache = await import('~/server/lib/cache/cache.js')
      cache = new __Cache.default('dato')

      // get from cache if available
      if (!draftMode) {
        try {
          const { data } = await useAsyncData(queryKey, async () => {
            return await cache.get(queryKey)
          })
          if (data) {
            rawJson = data.value
          }
        } catch (e) {
          const cached = await cache.get(queryKey)
          if (cached) {
            rawJson = cached.value
          }
        }
      }

      if (rawJson) {
        // process the data
        finalJson = rawJson
        if (options?.transform) {
          finalJson = await options.transform(finalJson)
        }
        return finalJson as Result
      }
    }

    // protect the query from being executed without an API token
    if (!apiToken) {
      throw new Error('Server: Missing API token')
    }

    // make the request to DatoCMS
    const initRequest = buildRequestInit(query, {
      token: apiToken,
      includeDrafts: draftMode,
      excludeInvalid: true,
    })

    async function _fetchDato(): Promise<any> {
      const response = await fetch('https://graphql.datocms.com/', {
        ...initRequest,
      })
      let responseJson = await response.json()
      responseJson = responseJson.data ?? responseJson
      return responseJson
    }

    try {
      const { data } = await useAsyncData(queryKey, async () => {
        return await _fetchDato()
      })
      rawJson = data
    } catch (error) {
      rawJson = await _fetchDato()
    }

    // store the response in cache
    if (!draftMode && options?.cache && __projectConfig.cache?.enabled) {
      let cacheValue = rawJson
      if (cacheValue?.value) {
        cacheValue = cacheValue.value
      }
      await cache.set(queryKey, cacheValue)
    }

    // process the data
    finalJson = rawJson.value ?? rawJson
    if (options?.transform) {
      finalJson = await options.transform(finalJson)
    }

    // return the data
    return finalJson as Result
  }
}
