import { graphql } from '~/integrations/dato/utils/graphql'
// import { type GraphQLTadaAPI, type TadaDocumentNode } from 'gql.tada';
import { TagFragment } from '~/integrations/dato/dato.js'
import { GridSectionFragment } from '~/components/grid/grid.fragment.js'
import { BlocksFragment } from '~/components/blocks/blocks.fragment.js'
import { useDatoQuery } from '../composables/useDatoQuery.js'
import type { TPage } from '~/components/pages/page/page.type.js'

export function getPage(slug: string, locale: string): Promise<TPage> {
  const query = graphql(
    /* GraphQL */ `
      {
        page(filter: {
          slug: {
            eq: "${slug}"
          }
        } locale: ${locale}) {
          _seoMetaTags {
            ...TagFragment
          }
          id
          title
          sections {
            ... on GridSectionRecord {
              ...GridSectionFragment
            }
            ...BlocksFragment
          }
          _allSlugLocales {
            locale
            value
          }
        }
      }
    `,
    [GridSectionFragment, BlocksFragment, TagFragment],
  )

  // @ts-ignore
  return useDatoQuery(query, {
    key: `page-${slug}-${locale}`,
    transform(data) {
      return data.page ?? data
    },
  })
}
