import * as __layoutModel from './layout.model.js'
import * as __LocalModel from './locales.model.js'
import * as __MenuModel from './menus.model.js'
import * as __MetasModel from './metas.model.js'
import * as __PageModel from './pages.model.js'
import * as __HomepageModel from './homepage.model.js'
import * as __RedirectsModel from './redirects.model.js'
import * as __SearchModel from './search.model.js'
import * as __PresetsModel from './presets.model.js'

export default {
  metas: __MetasModel,
  pages: __PageModel,
  homepage: __HomepageModel,
  locales: __LocalModel,
  menus: __MenuModel,
  redirect: __RedirectsModel,
  search: __SearchModel,
  layout: __layoutModel,
  presets: __PresetsModel,
}
