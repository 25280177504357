import { graphql } from '~/integrations/dato/utils/graphql'
import { ImageModuleFragment } from '~/components/ui/image/image.fragment.js'
import { BodyModuleFragment } from '~/components/ui/body/body.fragment.js'
import { HeroSectionFragment } from '~/components/hero/hero.fragment.js'
import { MediaModuleFragment } from '~/components/media/media.fragment.js'
import { AccordionModuleFragment } from '~/components/accordion/accordion.fragment.js'
import { CardModuleFragment } from '~/components/ui/card/card.fragment.js'
import { SpacerModuleFragment } from '~/components/ui/spacer/spacer.fragment.js'
import { SliderModuleFragment } from '~/components/slider/slider.fragment.js'
import { QuoteModuleFragment } from '../quote/quote.fragment.js'
import { TextModuleFragment } from '../ui/text/text.fragment.js'
import { FormModuleFragment } from '../ui/form/form.fragment.js'

export const BlocksFragment = graphql(
  /* GraphQL */ `
    fragment BlocksFragment on RecordInterface {
      ... on RecordInterface {
        id
        __typename
      }
      ... on HeroSectionRecord {
        ...HeroSectionFragment
      }
      ... on BodyModuleRecord {
        ...BodyModuleFragment
      }
      ... on MediaModuleRecord {
        ...MediaModuleFragment
      }
      ... on AccordionModuleRecord {
        ...AccordionModuleFragment
      }
      ... on CardModuleRecord {
        ...CardModuleFragment
      }
      ... on SpacerModuleRecord {
        ...SpacerModuleFragment
      }
      ... on SliderModuleRecord {
        ...SliderModuleFragment
      }
      ... on QuoteModuleRecord {
        ...QuoteModuleFragment
      }
      ... on TextModuleRecord {
        ...TextModuleFragment
      }
      ... on FormModuleRecord {
        ...FormModuleFragment
      }
    }
  `,
  [
    BodyModuleFragment,
    HeroSectionFragment,
    ImageModuleFragment,
    MediaModuleFragment,
    AccordionModuleFragment,
    CardModuleFragment,
    SpacerModuleFragment,
    SliderModuleFragment,
    QuoteModuleFragment,
    TextModuleFragment,
    FormModuleFragment,
  ],
)
