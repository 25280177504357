import { graphql } from '~/integrations/dato/utils/graphql'
// import { type GraphQLTadaAPI, type TadaDocumentNode } from 'gql.tada';
import { TagFragment } from '~/integrations/dato/dato.js'
import { GridSectionFragment } from '~/components/grid/grid.fragment.js'
import { BlocksFragment } from '~/components/blocks/blocks.fragment.js'
import { useDatoQuery } from '../composables/useDatoQuery.js'
import type { THomepage } from '~/components/pages/homepage/homepage.type.js'

export async function getPage(
  slug: string,
  locale: string,
): Promise<THomepage> {
  const query = graphql(
    /* GraphQL */ `
      {
        homepage(locale: ${locale}) {
          _seoMetaTags {
            ...TagFragment
          }
          id
          title
          featuredImage {
            url
          }
          featuredVideo {
            url
          }
          sections {
            ... on GridSectionRecord {
              ...GridSectionFragment
            }
            ...BlocksFragment
          }
          _allSlugLocales {
            locale
            value
          }
        }
      }
    `,
    [GridSectionFragment, BlocksFragment, TagFragment],
  )

  // @ts-ignore
  return useDatoQuery(query, {
    key: `homepage-${slug}-${locale}`,
    transform(data) {
      return data.homepage ?? data
    },
  })
}
